import React from 'react';
import * as S from './GameItem.style';
import {IconHeart, Tags} from 'components/ui';
import {useGameItem} from 'hooks';
import {assetURL, If} from 'helpers';

export const GameItem = React.forwardRef(({isGrid, game, src, ...props}, ref) => {

    const {isFavorite, favIconRef, animateAndToggle} = useGameItem(game);

    const GameComponent = isGrid ? S.GameGridItem : S.GameItem;
    const FavoriteIcon  = isGrid ? S.FavoriteGridItem : S.FavoriteIcon;


    return (
        <GameComponent ref={ref} {...props} srcImage={src ? assetURL(src) : ''} data-alias={game.alias}>

            <FavoriteIcon key={`favicon-${game.alias}`} ref={favIconRef} checked={isFavorite} onClick={animateAndToggle}>
                <IconHeart/>
            </FavoriteIcon>

            <If condition={game.release_status === 'new' || game.has4k}>
                <S.TagsWrapper>
                    <Tags isNew={game.release_status === 'new'} is4K={game.has4k}/>
                </S.TagsWrapper>
            </If>

            <If condition={!src}>
                <S.GameItemTextFallback>
                    {game.assets.title}
                </S.GameItemTextFallback>
            </If>

        </GameComponent>
    );
});
