import * as S from './SearchBox.style';
import {useTranslation} from 'react-i18next';
import {Input, NeutralButton} from 'components/ui';
import React, {useEffect} from 'react';
import {SpatialNavSection} from 'context';
import {Scrollable} from '../../../scroll-area';

export function SearchBox({onSearch, currentCue, onMoveDown, onFocus, onBlur, searchInputRef, isGapOpen, lastFocused})
{
    const {t} = useTranslation();

    useEffect(() => {
        // We cannot use currentCue as a prop on <Input /> cause we need
        // to set an onChange handler that we dont want
        searchInputRef.current.value = currentCue;
    }, [currentCue]);

    const onInputKeyDown = (e) => {
        if (e.keyCode === 13)
            onSearch(searchInputRef.current.value);
    };

    return (
        <Scrollable isGapOpen={isGapOpen} lastFocused={lastFocused}>
            <SpatialNavSection>
                <S.Wrapper>
                    <S.InputWrapper>
                        <Input defaultElement onFocus={onFocus} onBlur={onBlur} ref={searchInputRef} onKeyDown={onInputKeyDown}
                               overrideMoveDown={onMoveDown}
                               name="username"
                               placeholder={t('search.placeholder')}/>
                    </S.InputWrapper>
                    <S.ButtonWrapper>
                        <NeutralButton overrideMoveDown={onMoveDown} overrideMoveRight={() => false}
                                       onClick={() => onSearch(searchInputRef.current.value)}>{t('buttons.search')}</NeutralButton>
                    </S.ButtonWrapper>
                </S.Wrapper>
            </SpatialNavSection>
        </Scrollable>
    );
}
