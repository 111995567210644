export function IconPower(props)
{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.747"
            height="30.569"
            viewBox="0 0 26.747 30.569"
            {...props}>
            <defs>
                <clipPath id="iconPower">
                    <path d="M0 0H26.747V30.569H0z"/>
                </clipPath>
            </defs>
            <g clipPath="url(#iconPower)">
                <path
                    d="M0 15.641a14.805 14.805 0 011.4-4.523 13.3 13.3 0 017.648-6.621c.146-.051.3-.079.5-.134v4.074A9.648 9.648 0 004.3 19.987a9.494 9.494 0 0018.143 0A9.65 9.65 0 0017.2 8.437V4.212a32.811 32.811 0 013.083 1.485 13.135 13.135 0 016.39 9.717 1.236 1.236 0 00.076.223v3.1a3.476 3.476 0 00-.088.344 13.283 13.283 0 01-7.264 10.1 15.028 15.028 0 01-4.47 1.382h-3.1a2.348 2.348 0 00-.281-.085 13.259 13.259 0 01-10.163-7.26A14.958 14.958 0 010 18.746z"/>
                <path d="M15.284 0c.011.159.032.316.032.475V15.26h-3.838a4.7 4.7 0 01-.045-.471V.355c0-.118.019-.237.031-.355z"/>
            </g>
        </svg>
    );
}
