import {useEffect, useLayoutEffect, useState} from 'react';
import * as S from './GlobalPwaInstructions.style';
import {useTranslation} from 'react-i18next';
import {If} from 'helpers';
import {DownloadAndroid} from './android/DownloadAndroid';
import {DownloadIos} from './ios/DownloadIos';
import {hasRequiredIOSVersion, isAndroid, isIOS, isMobileOrTablet, isSafari, isStandalone} from 'app/device';
import {useAddToHomeScreenPrompt} from 'hooks';
import {IconRotate} from 'components/ui';
import {useInputDispatcherContext} from '../../context';

// see public/var.js
// eslint-disable-next-line no-undef
const appName = APP_TITLE || process.env.REACT_APP_TITLE;

export function GlobalPwaInstructions({debug})
{
    const {t}                                                 = useTranslation();
    const {prompt, promptToInstall}                           = useAddToHomeScreenPrompt();
    const [showAndroidDownloadBtn, setShowAndroidDownloadBtn] = useState(false);
    const {pauseInputDispatch, resumeInputDispatch} = useInputDispatcherContext();

    useEffect(() => {
        if (prompt)
            setShowAndroidDownloadBtn(true);
    }, [prompt]);

    // IF PORTRAIT - disable scroll and inputs
    const handleResize = () => {
        const bodyStyle = document.body.style;

        if (isMobileOrTablet && (window.innerHeight > window.innerWidth))
        {
            pauseInputDispatch();
            bodyStyle.overflow = 'hidden';
            bodyStyle.pointerEvents = 'none';
        } else {
            resumeInputDispatch();
            bodyStyle.overflow = '';
            bodyStyle.pointerEvents = 'auto';
        }
    };

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.addEventListener('resize', handleResize);
    }, []);

    if (!isStandalone())
    {
        return (
            <If condition={!debug}>
                <S.InstructionsWrapper>
                    <S.Box>
                        <S.LogoWrapper>
                            <S.FakeIcon/>
                            <S.FakeIcon/>
                            <S.LogoIcon/>
                            <S.FakeIcon/>
                            <S.FakeIcon/>
                        </S.LogoWrapper>
                        <S.TextWrapper>
                            <S.InstallTitle>
                                {t('pwa.install', {appName})}
                            </S.InstallTitle>
                            <S.InstallText>
                                <If condition={isAndroid}>
                                    {t('pwa.install_description')}
                                </If>
                                <If condition={isIOS}>
                                    <If condition={!hasRequiredIOSVersion}>
                                        {t('pwa.ios_version')}
                                    </If>
                                    <If condition={hasRequiredIOSVersion}>
                                        <If condition={isSafari}>
                                            {t('pwa.install_description')}
                                        </If>
                                        <If condition={!isSafari}>
                                            {t('pwa.install_safari')}
                                        </If>
                                    </If>
                                </If>
                                <If condition={!isIOS && !isAndroid}>
                                    {t('pwa.install_unavailable')}
                                </If>
                            </S.InstallText>
                        </S.TextWrapper>
                        {/*Show install instruction for Android or iOS*/}
                        <If condition={isAndroid && showAndroidDownloadBtn}>
                            <DownloadAndroid onClick={promptToInstall}/>
                        </If>
                        <If condition={isIOS && hasRequiredIOSVersion && isSafari}>
                            <DownloadIos/>
                        </If>
                    </S.Box>
                </S.InstructionsWrapper>
            </If>
        );
    }
    else
    {
        return (
            <S.InstructionsWrapperLandscape>
                <S.RotateYourPhone>
                    <S.RotateText>{t('pwa.rotate')}</S.RotateText>
                    <S.SvgWrapper>
                        <IconRotate/>
                    </S.SvgWrapper>
                </S.RotateYourPhone>
            </S.InstructionsWrapperLandscape>
        );
    }
}


