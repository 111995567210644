import React from 'react';

export const IconClose = React.forwardRef((props, forwardedRef) => {
    return (
        <svg
            ref={forwardedRef}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 77 74"
            {...props}
        >
            <path
                d="M40-.03h-3a37 37 0 000 74h3a37 37 0 000-74zm8.51 40.75c3.53 3.58 2.95 3 6.52 6.56a5.72 5.72 0 011.91 4.28 5.65 5.65 0 01-.7 2.72 5.58 5.58 0 01-7.58 2.16 8.73 8.73 0 01-1.84-1.41c-4.25-4.23-5.62-5.63-9-9-3.64 3.66-5.27 5.28-9.49 9.43-2.62 2.58-5.9 2.6-8.25.22l-.14-.14a5.42 5.42 0 01.14-7.67c2.23-2.33 1.1-1.13 3.38-3.41 1.37-1.37 2.75-2.73 4.11-4.11 1.87-1.9 1.91-3.65.05-5.54-3.54-3.59-3-3-6.53-6.56a5.78 5.78 0 01-1.91-4.29 5.58 5.58 0 018.29-4.88 8.67 8.67 0 011.83 1.41c4.25 4.23 5.63 5.63 9 9 3.65-3.66 6-6 10.18-10.12 2.62-2.58 5.9-2.6 8.25-.22l.14.15a5.41 5.41 0 01-.14 7.66c-2.24 2.33-1.79 1.82-4.08 4.11l-4.1 4.1c-1.87 1.93-1.91 3.69-.04 5.58z"
                fill="#fff"
                data-name="Close Button"
            />
        </svg>
    );
});

