export function IconSettings(props)
{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 27.715 27.715"
            {...props}>
            <path
                d="M24.382 15.243a8.666 8.666 0 00.071-1.386c0-.485-.071-.9-.071-1.386l2.976-2.286a.636.636 0 00.142-.9l-2.835-4.781a.69.69 0 00-.851-.277l-3.543 1.385a10.315 10.315 0 00-2.409-1.387l-.5-3.672a.759.759 0 00-.709-.554h-5.666a.759.759 0 00-.709.554l-.566 3.672a12 12 0 00-2.41 1.386L3.759 4.225a.664.664 0 00-.851.277L.073 9.285a.837.837 0 00.142.9l3.047 2.286c0 .485-.071.9-.071 1.386s.071.9.071 1.386L.287 17.525a.636.636 0 00-.142.9l2.835 4.781a.69.69 0 00.851.277l3.543-1.386a10.313 10.313 0 002.409 1.386l.567 3.672a.687.687 0 00.709.554h5.666a.759.759 0 00.709-.554l.567-3.672a11.994 11.994 0 002.409-1.386l3.543 1.386a.664.664 0 00.851-.277l2.835-4.781a.836.836 0 00-.142-.9zm-10.557 3.465a4.851 4.851 0 114.961-4.85 4.876 4.876 0 01-4.961 4.85z"/>
        </svg>
    );
}
