import React from 'react';
import * as S from './RadioButton.style';

export function RadioButton({focusOnMount, onRadioButtonClick, radioButtonStatus, label, value, ...props})
{
    return (
        <S.RadioButtonWrapper onClick={onRadioButtonClick} {...props}>
            <S.ButtonRadio className={radioButtonStatus === value ? 'active' : ''}></S.ButtonRadio>
            <S.RadioButtonLabel>{label}</S.RadioButtonLabel>
        </S.RadioButtonWrapper>
    );
};
