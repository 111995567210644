import React, {useState} from 'react';
import {DangerButton, NeutralButton, RegularButton} from 'components/ui';
import * as S from './ActionButtons.style';
import {useTranslation} from 'react-i18next';
import {useMultiViewContext} from 'context/multi-view';
import {PlayButton, TrailerButton} from 'components/games';
import {If} from 'helpers';
import {SpatialNavSection} from 'context';

export function ActionButtons({onClose, game, onUnFavorite})
{

    const {t}                   = useTranslation();
    const {setView}             = useMultiViewContext();
    const [confirm, setConfirm] = useState(false);

    const unFav = () => {
        onClose();
        onUnFavorite(game.alias);
    };

    return (
        <SpatialNavSection defaultElement enterTo="default-element">
            <S.Wrapper hasQuickMatch={game.hasQuickMatch}>
                <PlayButton game={game} defaultElement/>
                <If condition={!onUnFavorite}>
                    <TrailerButton game={game}/>
                </If>
                <If condition={onUnFavorite}>
                    <If condition={!confirm}>
                        <NeutralButton title={t('buttons.unfavorite')} onClick={() => setConfirm(true)}>{t('buttons.unfavorite')}</NeutralButton>
                    </If>
                    <If condition={confirm}>
                        <DangerButton focusOnMount
                                      title={t('buttons.confirm')}
                                      onBlur={() => setConfirm(false)}
                                      onClick={unFav}>
                            {t('buttons.confirm')}
                        </DangerButton>
                    </If>
                </If>
                <If condition={game.hasQuickMatch}>
                    <RegularButton onClick={() => setView('quick-match')}>{t('buttons.quick-match')}</RegularButton>
                </If>
                <RegularButton onClick={() => setView('more-view')}>{t('buttons.more')}</RegularButton>
            </S.Wrapper>
        </SpatialNavSection>
    );
}
