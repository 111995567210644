import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import * as InputDispatch from 'hooks/input-dispatch';
import SpatialNav from './SpatialNav';
import {useInputDispatcherContext} from '../input-dispatcher/InputDispatcherContext';

const SpatialNavContext = React.createContext(null);

export const useSpatialNavContext = () => useContext(SpatialNavContext);

export function SpatialNavProvider({children, debugDraw, debug})
{
    const {pushLayer, popLayer}             = useInputDispatcherContext();
    const SN                                = useRef(new SpatialNav({debug_draw: debugDraw, debug: debug})).current;
    const lastFocused                       = document.activeElement;
    const [shouldRefocus, setShouldRefocus] = useState(true);

    // Get previous focused Element
    const getPreviousFocusEl = useCallback(() => SN.getPreviousFocusEl(), []);

    // Add focusable to SN tree
    const addFocusable = useCallback(params => SN.addFocusable(params), []);

    // Remove focusable from SN tree
    const removeFocusable = useCallback(params => SN.removeFocusable(params), []);

    // Focus item
    const setFocus = useCallback(params => SN.setFocus(params), []);

    // Focus Item and mark need to refocus as false
    const refocus = useCallback(params => {
        setFocus(params);
        setShouldRefocus(false);
    }, [setFocus]);

    // Make element focusable
    const makeFocusable = useCallback(params => SN.makeFocusable(params), []);

    // Enable || disable focusable
    const setEnabled = useCallback((domEl, state, parentState) => SN.setEnabled(domEl, state, parentState), []);

    // Refresh tree rects
    const refreshTree = useCallback((params) => SN.refreshTreeRects(params), []);

    // Reset last focused elements
    const resetLastFocused = useCallback(() => {
        // clear the last focus information in the nav bar
        // so that next focus will recompute the default element correctly
        SN.resetLastFocused();
        setShouldRefocus(true);
    }, []);

    // Set section default node
    const setSectionDefaultNode = useCallback((sectionDomEl, childEl) => SN.setSectionDefaultNode(sectionDomEl, childEl), []);

    useEffect(() => {

        // Push a new layer
        pushLayer();

        // Pop layer
        return () => popLayer();

    }, []);


    // Input handlers
    const moveUp = useCallback(() => SN.moveUp(), []);
    InputDispatch.useUpPress(moveUp);
    const moveDown = useCallback(() => SN.moveDown(), []);
    InputDispatch.useDownPress(moveDown);
    const moveLeft = useCallback(() => SN.moveLeft(), []);
    InputDispatch.useLeftPress(moveLeft);
    const moveRight = useCallback(() => SN.moveRight(), []);
    InputDispatch.useRightPress(moveRight);
    const click = useCallback(() => document.activeElement.click(), []);
    InputDispatch.useSelectPress(click);

    useEffect(() => {
        return () => {
            // focus last element when this provider is unmounted
            if (lastFocused)
                lastFocused.focus();
        };
    }, []);

    return (
        <SpatialNavContext.Provider value={{
            addFocusable, removeFocusable,
            setFocus, makeFocusable, setEnabled,
            refreshTree, resetLastFocused, shouldRefocus, refocus,
            setSectionDefaultNode,
            getPreviousFocusEl
        }}>
            {children}
        </SpatialNavContext.Provider>
    );
}
