import {defaultErrorHandler, doRequest, StatusCodes} from 'helpers';
import {Endpoints, PrivateClient as client} from 'api';
import Authentication from './Authentication';

class Profile {

    constructor(options)
    {
        this.options = options || {};
    }

    async Preload(preloadCallback)
    {
        return doRequest({
            request         : client.get(Endpoints.PROFILES),
            [StatusCodes.OK]: response => {
                if (this.options.debug)
                {
                    console.groupCollapsed('PROFILE - ACCOUNT INFOS');
                    console.log('ACCOUNT STATUS :', Authentication.GetUserStatus());
                }

                // Format initial profiles
                preloadCallback(response.data.map((profile, i) => {
                        let mappedProfile = {
                            profileName  : profile['nickname'],
                            uid          : profile['uid'],
                            locked       : profile['has_pin_code'],
                            avatar       : {
                                alias: profile['avatar_alias'],
                                file : profile['avatar_filename']
                            },
                            contentRating: {
                                alias: profile['content_rating'],
                            }
                        };

                        if (this.options.debug)
                        {
                            console.log(`profile ${i + 1} :`);
                            console.log(mappedProfile);
                        }

                        return mappedProfile;
                    }
                ));
                if (this.options.debug)
                    console.groupEnd();
            }
        });
    }

    async CheckPinCode({profileUID, pinCode})
    {
        return doRequest({
            request                : client.post(Endpoints.CHECK_PIN_CODE, {
                'profileUid': profileUID,
                'pinCode'   : pinCode,
            }),
            [StatusCodes.OK]       : true,
            [StatusCodes.FORBIDDEN]: false,
            'default'              : defaultErrorHandler
        });
    }

    async CreateProfile({password, profileName, avatar, contentRating, locked, pinCode})
    {
        let data = {
            playerPassword  : password,
            nickname        : profileName,
            avatar          : avatar.alias,
            maxContentRating: contentRating.alias,
        };
        if (locked)
            data.pinCode = pinCode;
        return doRequest({
            request                     : client.post(Endpoints.PROFILES, data),
            [StatusCodes.OK]            : ({data}) => {
                return {
                    hasError: false,
                    realName: data.nickname, // Backend may change the profile name
                    uid     : data.uid,
                };
            },
            [StatusCodes.UNAUTHORIZED]  : defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
            [StatusCodes.CONFLICT]      : defaultErrorHandler,
            [StatusCodes.FORBIDDEN]     : {hasError: true, error: {message: 'error.try_again_later'}}
        });
    }

    async UpdateProfile({password, uid, avatar, profileName, contentRating, locked, pinCode, oldState})
    {

        let data = {};

        // Avatar
        if (avatar.alias !== oldState.avatar.alias)
            data.avatar = avatar.alias;

        // Profile name
        if (profileName !== oldState.profileName)
            data.nickname = profileName;

        // Content rating
        if (contentRating.alias !== oldState.contentRating.alias)
            data.maxContentRating = contentRating.alias;

        // Remove PinCode
        if (!locked && oldState.locked)
            data.removePinCode = true;

        // PinCode
        if (locked && pinCode.length)
            data.pinCode = pinCode;

        // Check diff
        if (Object.keys(data).length === 0)
            return Promise.resolve(true);

        // Set player password
        data.playerPassword = password;

        return doRequest({
            request                     : client.put(`${Endpoints.PROFILES}/${uid}`, data),
            [StatusCodes.OK]            : ({data}) => {
                return {
                    hasError: false,
                    realName: data.nickname, // Backend may change the profile name
                };
            },
            [StatusCodes.UNAUTHORIZED]  : defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
            [StatusCodes.CONFLICT]      : defaultErrorHandler,
            [StatusCodes.FORBIDDEN]     : {hasError: true, error: {message: 'error.try_again_later'}}
        });
    }

    async DeleteProfile({password, uid})
    {
        const data = {playerPassword: password};
        return doRequest({
            request                     : client.delete(`${Endpoints.PROFILES}/${uid}`, {data: data}),
            [StatusCodes.OK]            : {hasError: false},
            [StatusCodes.UNAUTHORIZED]  : defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
        });

    }
}

export default new Profile({
    debug: process.env.NODE_ENV === 'development',
});
