import {Games, LocalStore} from 'services';
import {isMobile} from '../app/device';

class Search {

    constructor()
    {
        this.store = JSON.parse(LocalStore.Get('search')) || {
            mostResearchedCues : [],
            mostResearchedGames: [],
        };
    }

    Find(cue, t)
    {
        cue         = cue.toLowerCase();
        let results = Games.GetGames().filter(game =>
            game.assets.title.toLowerCase().includes(cue) ||
            // user-visible (localized) category names
            game.category.some(c => t(`categories.${c.toLowerCase()}`).toLowerCase().includes(cue)) ||
            // publisher may be empty in some staging environments
            game.publisher?.toLowerCase().includes(cue) ||
            // only search description for larger cues, may also be empty in some staging environments
            (cue.length >= 4 && game.assets.description?.toLowerCase().includes(cue))
        );
        // some locales (e.g. chinese) may not have title translations and require an extra
        // search in the english title if main search yields no result
        // eslint-disable-next-line no-undef
        if (results.length === 0 && APP_ENGLISH_SEARCH)
        {
            results = Games.GetGames().filter(game => game.assets.en?.title?.toLowerCase().includes(cue));
        }

        if (results.length > 0)
            this.UpdateMostResearched(cue, results);

        return results;
    }

    UpdateMostResearched(cue, games)
    {
        // Update cues
        this.UpdateEntries(this.store.mostResearchedCues, cue);

        // Update games
        games.forEach(game => this.UpdateEntries(this.store.mostResearchedGames, game.name));

        // Sort cues
        this.Sort(this.store.mostResearchedCues);

        // Sort games
        this.Sort(this.store.mostResearchedGames);

        // Limit cues to 5
        this.store.mostResearchedCues = this.store.mostResearchedCues.slice(0, 5);

        // Limit games to 6
        this.store.mostResearchedGames = this.store.mostResearchedGames.slice(0, 6);

        // Commit to store
        this.Commit();
    }

    UpdateEntries(entries, entryName)
    {
        let cueEntry = entries.find(entry => entry.name === entryName);
        if (cueEntry)
            cueEntry.count++;
        else
        {
            entries.push({
                name     : entryName,
                count    : 0,
                timestamp: new Date().getTime()
            });
        }
    }

    Sort(entries)
    {
        entries.sort((a, b) => {

            // Sort by count
            if (a.count > b.count) return -1;
            if (a.count < b.count) return 1;

            // Sort by timestamp
            if (a.timestamp > b.timestamp) return -1;
            if (a.timestamp < b.timestamp) return 1;

            return 0;
        });
    }

    GetMostResearchedCues()
    {
        return this.store.mostResearchedCues;
    }

    GetMostResearchedGames()
    {
        // TODO : replace with API when it goes live
        const highlights = Games.GetHighLights().games;
        return isMobile ? highlights.slice(0, 4) : highlights.slice(0, 6);
    }

    Commit()
    {
        LocalStore.Set('search', JSON.stringify(this.store));
    }
}

export default new Search();
