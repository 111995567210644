import {MostSearched, SearchBox, SearchBoxWrapper} from 'components/games/search';
import {GamesWrapper, GameWindow, Grid, NoGamesFound} from 'components/games';
import {useGameWindow, useSearch} from 'hooks';
import {ColumnLayout} from 'components/ui';
import {If} from 'helpers';
import {useRef, useState} from 'react';
import {useSpatialNavContext} from 'context';
import {BigTitleWrapper, GlassTitleWrapper} from 'components/ui/glass-title/GlassTitle.style';
import {isAndroid} from 'app/device';
import {GlassTitle} from 'components/ui/glass-title/GlassTitle';
import {useTranslation} from 'react-i18next';

export function SearchScreen()
{
    const {
              games: searchGames, columns, onSearch, cues, currentCue, mostSearchedGames, isMostSearchedVisible, onFocusSearchBox,
              onBlurSearchBox, searchInputRef
          }                    = useSearch();
    const [games, setGames]    = useState(searchGames);
    const gameWindow           = useGameWindow(games);
    const cuesRef              = useRef();
    const glassTitleWrapperRef = useRef();
    const {setFocus}           = useSpatialNavContext();
    const {t}                  = useTranslation();

    const openWindowWithGameList = gameList => alias => {
        setGames(gameList);
        gameWindow.open(alias);
    };

    return (
        <ColumnLayout alignItems="center" fullSize>
            <If condition={gameWindow.visible}>
                <GameWindow index={gameWindow.selectedIndex}
                            indexMax={games.length - 1}
                            defaultView={gameWindow.defaultView}
                            game={games[gameWindow.selectedIndex]}
                            onClose={gameWindow.close}
                            onPrev={gameWindow.prev}
                            onNext={gameWindow.next}
                            swipeHandlers={gameWindow.handlers}
                            ref={gameWindow.gameWindowRef}/>
            </If>
            <GamesWrapper>
                <ColumnLayout fullWidth>
                    <SearchBoxWrapper>
                        <BigTitleWrapper isSearch={true}>
                            <GlassTitleWrapper ref={glassTitleWrapperRef} isVisible={!gameWindow.visible}>
                                <GlassTitle wrapperRef={glassTitleWrapperRef}>{t('navigation.search')}</GlassTitle>
                            </GlassTitleWrapper>
                            <SearchBox currentCue={currentCue}
                                       onFocus={onFocusSearchBox}
                                       onBlur={onBlurSearchBox}
                                       isGapOpen={gameWindow.gapOpen}
                                       lastFocused={gameWindow.lastFocused}
                                       searchInputRef={searchInputRef}
                                       onMoveDown={() => cuesRef.current ? setFocus(cuesRef.current) : true}
                                       onSearch={onSearch}/>
                            {/*
                                    If NOT Android always display MostSearched otherwise check with isMostSearchedVisible (checking if android virtual keyboard is opened or not) to
                                    prevent layout shift
                                */}
                            <If condition={!isAndroid || isMostSearchedVisible}>
                                <MostSearched ref={cuesRef} cues={cues}
                                              onCueSelected={onSearch}
                                              isGapOpen={gameWindow.gapOpen}
                                              lastFocused={gameWindow.lastFocused}
                                              MostSearchedGames={mostSearchedGames}
                                              onItemClicked={openWindowWithGameList(mostSearchedGames)}/>
                            </If>
                        </BigTitleWrapper>
                    </SearchBoxWrapper>
                    <If condition={searchGames.length <= 0 && currentCue !== ''}>
                        <NoGamesFound small={true}>
                            {t('search.no_games')}
                        </NoGamesFound>
                    </If>
                    <If condition={searchGames.length > 0}>
                        <Grid isGapOpen={gameWindow.gapOpen}
                              lastFocused={gameWindow.lastFocused}
                              onItemClicked={openWindowWithGameList(searchGames)}
                              columns={columns}
                              key={currentCue}
                        />
                    </If>
                </ColumnLayout>
            </GamesWrapper>
        </ColumnLayout>
    );
}
