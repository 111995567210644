const Theme = {

    // Blur
    BLUR_AMOUNT            : 39,  // Unit : px
    LIGHT_BLUR_BRIGHTNESS  : 100, // Unit : %
    DARK_BLUR_BRIGHTNESS   : 90,  // Unit : %
    DARK_BLUR_BRIGHTNESS_TV: 70,  // UNIT : %

    COLORS: {

        // WHITE
        WHITE: '#ffffff',
        BLACK: '#000000',

        // BLUE
        BLUE_L0      : '#79e2e5',
        BLUE_L1      : '#0078ff',
        BLUE_L2      : '#0030ff',
        BLUE_L2_ALPHA: 'rgba(00, 48, 255, 0.5)', // Tizen does not support hex alpha
        BLUE_L3      : '#0030ff',

        // GREY
        // TODO: switch L0 and L1 for consistency (L1 is lighter)
        GRAY_L0: '#a3a3a3',
        GRAY_L1: '#d0d0d0',
        GRAY_L2: '#969696',
        GRAY_L3: '#898989',
        GRAY_L4: '#868686',
        GRAY_L5: '#707070',

        // RED
        RED_L1: '#ea334c',
        RED_L2: '#ff4b60',
        RED_L3: '#ff7777',

        // Orange
        ORANGE_L1: '#f4b43e',

        // Green
        GREEN_L1: '#75fbc8',
    }
};

export default Theme;

