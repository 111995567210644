import {Endpoints, PrivateClient as client} from 'api';
import {doRequest, StatusCodes} from 'helpers';

import {supportedLanguages} from 'i18n';

class ConfigFront {

    constructor()
    {
        this.config = {};
    }


    GetConfig()
    {
        return this.config;
    }

    GetAssetsUrl()
    {
        // allow override from env in development, otherwise use value from backend config
        const envUrl = process.env.NODE_ENV === 'development' && process.env.REACT_APP_ASSETS_URL;
        return envUrl || this.config.assets_server;
    }

    GetChatServerUrl()
    {
        // allow override from env in development, otherwise use value from backend config
        const envUrl = process.env.NODE_ENV === 'development' && process.env.REACT_APP_CHAT_SERVER_URL;
        return envUrl || this.config.chat_web_socket_host + ':' + this.config.chat_web_socket_port;
    }

    GetRegisterUrl()
    {
        return this.config.how_to_register_url;
    }

    GetResetPasswordUrl()
    {
        return this.config.change_password_url;
    }

    GetSupportedLanguages()
    {
        return this.config.languages.split(',').filter(lang => supportedLanguages.includes(lang));
    }

    GetSupportMethods()
    {
        let methods = [];

        if (this.config.support_email)
            methods.push({email: this.config.support_email});

        if (this.config.support_url)
            methods.push({url: this.config.support_url});

        return methods;
    }

    Load()
    {
        return doRequest({
            request         : client.get(`${Endpoints.CONFIG_FRONT}/default`),
            [StatusCodes.OK]: ({data}) => this.config = data
        });
    }

}

export default new ConfigFront();
