import {useCallback, useEffect, useRef, useState} from 'react';
import {useScrollGap} from 'hooks';
import {isMobile} from 'app/device';
import {setSubMenuVisible} from 'slices';
import {useDispatch} from 'react-redux';
import {Games} from 'services';

export function useGameWindow(games)
{
    const scrollGap                         = useScrollGap();
    const [visible, setVisible]             = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAlias, setSelectedAlias] = useState('');
    const dispatch                          = useDispatch();
    const lastFocused                       = useRef();
    const defaultView                       = useRef('');
    const gameWindowRef                     = useRef();

    const quickMatch = (alias) => {
        defaultView.current = 'quick-match';
        open(alias);
    };

    const open = (alias) => {
        lastFocused.current = document.activeElement;
        // in some screens (charts, search), the game list may be changed just before the window
        // is opened so the index cannot be set directly, otherwise there would be a short time
        // with a mismatch (or no match) between the games list and the requested index
        // instead we set the selected alias, and the effect below will set the correct index
        // from the alias and games list once both are set, then make the window visible
        setSelectedAlias(alias);
        if (isMobile)
            dispatch(setSubMenuVisible(false));
        scrollGap.open();
    };

    // computes the selected index from the set of games and selected alias
    // and shows the window
    useEffect(() => {
        if (games.length === 0)
        {
            setVisible(false);
            return;
        }
        const index = games.indexOf(Games.GetGameFromAlias(selectedAlias));
        setSelectedIndex(index);
        if (index >= 0)
        {
            setVisible(true);
        }
    }, [games, selectedAlias]);

    const close = () => {
        setVisible(false);
        scrollGap.close();
        setSelectedAlias(null);
        if (isMobile)
            dispatch(setSubMenuVisible(true));
        defaultView.current = '';
    };

    const next = useCallback(() => {
        if (selectedIndex + 1 < games.length)
        {
            setSelectedIndex(selectedIndex + 1);
        }
    }, [selectedIndex, games]);

    const prev = useCallback(() => {
        if (selectedIndex - 1 >= 0)
        {
            setSelectedIndex(selectedIndex - 1);
        }
    }, [selectedIndex]);


    return {
        visible,
        defaultView,
        open,
        close,
        quickMatch,
        next,
        prev,
        selectedIndex,
        lastFocused,
        gapOpen: scrollGap.isOpen,
        gameWindowRef
    };
}
