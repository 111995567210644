import {useSelector} from 'react-redux';
import {favoritesSelector} from 'slices';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useToggleFavorite} from 'hooks/games/useToggleFavorite';

export function useGameItem(game)
{
    const {favorites}      = useSelector(favoritesSelector);
    const isFavorite       = useMemo(() => favorites.includes(game.alias), [game, favorites]);
    const favIconRef       = useRef();
    const favorite         = useToggleFavorite();

    useEffect(() => {
        animateHeart();
    }, [isFavorite]);

    const animateHeart = () => {
        const target = favIconRef.current;
        target.classList.add('animate');
        target.onanimationend = () => {
            target.classList.remove('animate');
        };
    };

    const onToggleFavorite = useCallback((e) => {
        e.stopPropagation();
        favorite.toggleGame(game.alias);

    }, [game, isFavorite]);

    const animateAndToggle = (e) => {
        e.stopPropagation();
        animateHeart();
        onToggleFavorite(e);
    };

    return {isFavorite, onToggleFavorite, favIconRef, animateAndToggle};
}
