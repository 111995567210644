import {createAsyncThunk} from '@reduxjs/toolkit';
import {askCode} from 'services/PasswordReset';

/** Thunks */
export const doAskCode = createAsyncThunk(
    'recovery/doAskCode',
    async (params) => {
        return await askCode(params);
    }
);

// Ask code
export const doAskCodeReducer = {
    [doAskCode.pending]: (state) => {
        state.recoveryError = false;
    },

    [doAskCode.fulfilled]: (state, {payload}) => {

        if (payload.error === false)
        {
            state.selectedMethod = payload.selectedMethod;
            state.step2Done      = true;
        }
        else
        {
            state.recoveryError = payload.error;
        }
    },

    [doAskCode.rejected]: (state) => {
        state.recoveryError = 'error.internal';
    },
};
