import {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {clamp, remap} from 'helpers';
import {isFirefox, isIOS, isSafari} from 'app/device';

export function useScrollView()
{
    const scrollViewRef                                 = useRef();
    const scrollBarWrapperRef                           = useRef();
    const scrollContentRef                              = useRef();
    const scrollBarThumbRef                             = useRef();
    const contentDimensions                             = useRef({}).current;
    const [isMouseDown, setIsMouseDown]                 = useState(false);
    const [showCustomScrollBar, setShowCustomScrollBar] = useState(false);

    const onMouseDown = () => {
        setIsMouseDown(true);
    };

    const onMouseUp = () => {
        setIsMouseDown(false);
    };

    const onMouseMove = (e) => {
        //check if mouse click while mouse move (dragging)
        if (!isMouseDown)
            return;

        updateScrollBar(e);
    };

    const onScroll = (e) => {
        //check if scrolling but not dragging (because fake scroll with dragging triggering 'scroll' eventListener);
        if (isMouseDown)
            return;

        updateScrollBar(e);
    };

    const updateScrollBar = (e) => {
        const high1                = scrollContentRef.current.scrollHeight - scrollContentRef.current.clientHeight;
        const high2                = scrollBarWrapperRef.current.offsetHeight - scrollBarThumbRef.current.offsetHeight;
        const verticalThumbPadding = 4;
        let valueToRemap;

        //if isDragging
        if (isMouseDown)
        {
            const mapToBounds = clamp(
                remap(
                    e.pageY,
                    contentDimensions.boundings.bottom,
                    contentDimensions.boundings.top,
                    0,
                    high1),
                0,
                high1
            );

            const remappedVal = clamp(
                remap(
                    mapToBounds,
                    high1, 0,
                    0, high1
                ), 0, high1
            );

            // translate scrollable content
            scrollContentRef.current.scrollTop = remappedVal;
            valueToRemap                       = remappedVal;
        }
        else
        {
            valueToRemap = e.target.scrollTop;
        }

        let translateVal = Math.round(remap(valueToRemap, 0, high1, verticalThumbPadding, high2 - verticalThumbPadding));

        // translate thumb scrollbar
        scrollBarThumbRef.current.style.transform = `translate3d(0, ${translateVal}px, 0)`;
    };

    const resetScrollBar = useCallback(() => {
        if (scrollContentRef.current)
        {
            //reset scrollbar thumb and content to top
            scrollContentRef.current.scrollTop = 0;
            contentDimensions.boundings        = scrollContentRef.current.getBoundingClientRect();
        }

        if (scrollBarThumbRef.current)
        {
            scrollBarThumbRef.current.style.transform = `translate3d(0, 4px, 0)`;
            //set scrollbar thumb proportionately to scroll content
            scrollBarThumbRef.current.style.height    = `${(scrollContentRef.current.clientHeight * 100) / scrollContentRef.current.scrollHeight}%`;
        }
    }, [scrollContentRef, scrollBarThumbRef]);

    const updateScrollbarVisibility = useCallback(() => {
        // prevent 1px diff when iOS
        const offset = 1;

        //check if scrollHeight > clientHeight, then display or not the scrollbar if content overflow. check browser, show custom scrollbar if (iOS AND Safari) OR FF
        if (((scrollContentRef.current.scrollHeight - offset) > scrollContentRef.current.clientHeight) && ((isIOS && isSafari) || isFirefox))
        {
            resetScrollBar();
            setShowCustomScrollBar(true);
        }
        else
        {
            setShowCustomScrollBar(false);
        }
    }, [scrollContentRef, resetScrollBar]);

    const onResize = () => {
        //refresh calc
        updateScrollbarVisibility();
    };

    useLayoutEffect(() => {
        //check if we should show scrollbar or not
        updateScrollbarVisibility();
    }, [updateScrollbarVisibility]);

    useLayoutEffect(() => {
        if (showCustomScrollBar)
        {
            //TODO: click inside scrollBar
            //Add listeners
            window.addEventListener('mousedown', onMouseDown);
            window.addEventListener('mouseup', onMouseUp);
            scrollContentRef.current.addEventListener('scroll', onScroll);
            scrollViewRef.current.addEventListener('mousemove', onMouseMove);

            return () => {
                //remove listeners
                window.removeEventListener('mousedown', onMouseDown);
                window.removeEventListener('mouseup', onMouseUp);
                scrollContentRef.current.removeEventListener('scroll', onScroll);
                scrollViewRef.current.removeEventListener('mousemove', onMouseMove);
            };
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [showCustomScrollBar]);

    return {scrollViewRef, showCustomScrollBar, scrollBarWrapperRef, scrollBarThumbRef, scrollContentRef};
}
