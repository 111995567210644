import styled, {css} from 'styled-components';
import {mobile, tablet} from 'app/device';

export const ScrollViewWrapper = styled.div`
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-top: 36px;
  padding-right: 1rem;
  display: flex;

  ${tablet(css`
    margin-top: 14px;
  `)}

  ${mobile(css`
    flex: 1;
    padding-right: 8px;
    margin-top: 14px;
  `)}
`;

export const ScrollContent = styled.div`
  overflow-y: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    display: ${props => props.showNativeScrollBar ? 'block' : 'none'};
  }

  scrollbar-width: ${props => props.showNativeScrollBar ? 'auto' : 'none'};
`;

export const ScrollBarWrapper = styled.div`
  width: 16px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  overflow: hidden;
`;

export const ScrollBarThumb = styled.div`
  background-color: white;
  width: 10px;
  min-height: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: inset 0 0 13px 12px rgba(255, 255, 255, 0.65);
  box-sizing: border-box;
  transform: translate3d(0, 4px, 0);
`;
