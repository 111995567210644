export function IconHeart(props)
{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 38.29 33.227"
            {...props}
        >
            <path
                d="M27.384 1h.508a.873.873 0 00.137.026 11.612 11.612 0 013.54.589 8.15 8.15 0 013.474 2.251 8.8 8.8 0 012.17 5.22c.022.22.048.439.072.659v1.343a.864.864 0 00-.035.135 9.447 9.447 0 01-.672 2.786 14.712 14.712 0 01-2.929 4.522c-.932 1-1.925 1.954-2.91 2.909q-3.065 2.973-6.151 5.927l-4.517 4.357a1.275 1.275 0 01-1.873-.01q-1.706-1.653-3.418-3.3-2.4-2.317-4.8-4.631-1.359-1.312-2.716-2.618c-.71-.683-1.441-1.347-2.129-2.051a19.02 19.02 0 01-2.206-2.781 11.916 11.916 0 01-1.788-4.339c-.057-.3-.1-.6-.143-.906v-1.38a1.642 1.642 0 00.035-.191 10.426 10.426 0 01.371-2.264 8 8 0 013.864-4.991 10.256 10.256 0 014.555-1.224c.191-.011.382-.026.574-.039h.508a2 2 0 00.209.033 8.639 8.639 0 013.522 1 18.886 18.886 0 014.018 2.886c.163.15.322.3.485.456a1.114 1.114 0 00.1-.082 20.365 20.365 0 013.607-2.812 9.484 9.484 0 013.644-1.378c.296-.041.596-.067.894-.102z"
                stroke="rgba(255,255,255,0.47)"
                strokeWidth={2}
            />
        </svg>
    );
}
