export function IconPlus(props)
{
    return (
        <svg data-name="Layer 1" viewBox="0 0 59 59" {...props}>
            <defs>
                <clipPath id="iconPlus">
                    <path fill="fff" d="M0 0H59V59H0z"/>
                </clipPath>
            </defs>
            <g clipPath="url(#iconPlus)">
                <path
                    d="M45 27.09a3.86 3.86 0 00-3-1.12h-6.2c-1.79 0-2.6-.87-2.62-2.66v-3.9-3.88a3.64 3.64 0 00-3.57-3.71h-.13a3.7 3.7 0 00-3.83 3.57 3.38 3.38 0 000 .45v9.64h-8.57a5.71 5.71 0 00-1.54.2A3.76 3.76 0 0013 30.29 3.85 3.85 0 0016.91 33h6.22c1.78 0 2.6.87 2.6 2.66v7.12a3.63 3.63 0 003.57 3.71h.14A3.69 3.69 0 0033.28 43a3.38 3.38 0 000-.45v-9h8.57a6.14 6.14 0 001.54-.2A3.76 3.76 0 0046 28.71a3.92 3.92 0 00-1-1.63"/>
                <path
                    d="M29.5 6.56A22.94 22.94 0 116.56 29.5 22.94 22.94 0 0129.5 6.56m0-6.56A29.5 29.5 0 1059 29.5 29.5 29.5 0 0029.5 0"/>
            </g>
        </svg>
    );
}
