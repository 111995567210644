import {LogoNav, Menu} from 'components/ui';
import * as S from './GlobalNavigation.style';
import {SpatialNavSection, useSpatialNavContext} from 'context';
import {useGlobalNavigation} from 'hooks';
import {AsideNav} from './aside-nav/AsideNav';
import {useLayoutEffect, useRef} from 'react';

export function GlobalNavigation()
{
    const {navigation, currentTab, visible, subMenuVisible, onTabSelected, onSubTabSelected} = useGlobalNavigation();
    const menuWrapperRef                                                                     = useRef();
    const {refocus, setFocus, shouldRefocus}                                                 = useSpatialNavContext();


    // force focus on navbar when visible and a focus reset is necessary
    useLayoutEffect(() => {
        if (visible && shouldRefocus)
        {
            refocus(menuWrapperRef.current);
        }
    }, [visible, shouldRefocus]);

    return (
        <S.Wrapper isVisible={visible}>
            <S.Content>
                <S.LogoWrapper>
                    <LogoNav/>
                </S.LogoWrapper>
                <SpatialNavSection focusOnMount enterTo="last-focused">
                    <S.MenuWrapper ref={menuWrapperRef}>
                        <Menu items={Object.keys(navigation)}
                              namespace="navigation"
                              onItemSelected={onTabSelected}/>
                    </S.MenuWrapper>
                </SpatialNavSection>
                <S.AsideNavWrapper>
                    <AsideNav/>
                </S.AsideNavWrapper>
            </S.Content>
            {navigation[currentTab.name]?.subMenu && subMenuVisible &&
            <SpatialNavSection enterTo="last-focused">
                <S.SubContent>
                    <Menu items={navigation[currentTab.name].subMenu}
                          namespace={currentTab.name}
                          onItemSelected={onSubTabSelected}
                          preventMoveRight={true}
                          overrideMoveUp={() => setFocus(menuWrapperRef.current)}
                    />
                </S.SubContent>
            </SpatialNavSection>
            }
        </S.Wrapper>
    );
}
