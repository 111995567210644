// one-level shallow comparison between two objects
export const shallowEqual = (a, b) => Object.entries(a).every(([key, valueA]) => valueA === b[key]);

// recursive override of fields of a with fields of b
export const mergeObjects = (main, override, field) => {
    if (typeof main !== typeof override)
    {
        throw new Error(`Type mismatch between main and override ${field ? `for field '${field}'` : ''} during merge.`);
    }
    if (typeof main !== 'object')
    {
        return override || main;
    }
    const result = {};
    // copy or override fields of main
    Object.getOwnPropertyNames(main).forEach(p => {
        if (p in override)
        {
            result[p] = mergeObjects(main[p], override[p], p);
        }
        else
        {
            result[p] = main[p];
        }
    });
    // add override fields not in main
    Object.getOwnPropertyNames(override).forEach(p => {
        if (p in main)
        {
            return;
        }
        result[p] = override[p];
    });
    return result;
};
