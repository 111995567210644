export function IconLogout(props)
{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 110.918 111"
            {...props}
        >
            <path
                d="M69.328 111.001h.214q7.887-5.248 15.778-10.492 8.239-5.5 16.474-11c3.033-2.022 6.083-4.012 9.129-6.018V.08c-.36-.024-.72-.077-1.076-.077q-27.4-.006-54.8.008a12.5 12.5 0 00-2.363.239C46.46 1.439 41.517 7.178 41.513 13.278q-.017 35.031.008 70.066a13.793 13.793 0 0013.91 13.869c4.113.024 8.23 0 12.344.012.445 0 .89.085 1.4.134.029.489.064.845.064 1.2 0 2.237-.012 4.477 0 6.714.012 1.905.057 3.818.089 5.728zM55.662 83.093V14.127h33.889c-.489.352-.768.57-1.063.764-5.974 3.988-11.936 8-17.946 11.928a2.629 2.629 0 00-1.319 2.548q.055 26.1.02 52.2c0 .485-.064.971-.1 1.521zM0 50.775a7.639 7.639 0 002.289 3.7q8.275 8.184 16.465 16.454c1.881 1.905 4.077 2.14 6.479 1.537a2.959 2.959 0 002.443-2.928c.069-1.8.077-3.6.077-5.408q.012-17.261 0-34.525v-.212c-.036-3.592-2-5.226-5.586-4.635a5.8 5.8 0 00-3.3 1.682q-8.226 8.306-16.538 16.526a7.834 7.834 0 00-2.334 3.689z"/>
        </svg>
    );
}
