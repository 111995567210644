import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import uniqid from 'uniqid';
import KeyboardListener from './KeyboardListener';
import GamepadListener from './GamepadListener';
import {useSelector} from 'react-redux';
import {gameSessionSelector, SESSION_STATE} from 'slices';

const Context = React.createContext(null);

export const useInputDispatcherContext = () => useContext(Context);

const layer = () => ({
    back              : {},
    select            : {},
    up                : {},
    down              : {},
    left              : {},
    right             : {},
    triggerBottomLeft : {},
    triggerBottomRight: {},
    actionTop         : {}
});

const inputDevices = [new KeyboardListener(), new GamepadListener()];

export function InputDispatcherProvider({children})
{
    const layers                         = useRef(useMemo(() => [], [])).current;
    const [layersCount, setLayersChange] = useState(1);
    const {sessionState}                 = useSelector(gameSessionSelector);

    // Get number of Gamepads
    const getNumberOfGamepads = () => inputDevices[1].Count();

    // Get current layer
    const currentLayer = () => layers[layers.length - 1];

    // Called on input events
    const dispatchCallback = useCallback((button) => {
        // Evaluate all callbacks of the target button
        Object.values(currentLayer()[button])
              .forEach(callback => callback());
    }, []);

    // Register a button callback and returns its layer and unique id
    const registerCallback = useCallback((targetBtn, callback) => {
        let callbackId                        = uniqid();
        currentLayer()[targetBtn][callbackId] = callback;
        return [layers.length - 1, callbackId];
    }, []);

    // unRegister a button callback
    const unRegisterCallback = useCallback((targetBtn, layer, callbackId) => {
        // remove callback if its containing layer has not been destroyed yet
        if (layer < layers.length)
        {
            delete layers[layer][targetBtn][callbackId];
        }
    }, []);

    // Pause input dispatch
    const pauseInputDispatch = useCallback(() => {
        inputDevices.forEach(inputDevice => inputDevice.Stop());
    }, []);

    // Resume input dispatch
    // Test if we should resume input dispatcher based on session state, if game session is running we don't want the user being able to navigate on the UI
    const resumeInputDispatch = useCallback(() => {
        if (sessionState.id !== SESSION_STATE.RUNNING.id)
            inputDevices.forEach(inputDevice => inputDevice.Start(dispatchCallback));
    }, [sessionState, dispatchCallback]);

    // Push new layer
    const pushLayer = useCallback(() => {
        layers.push(layer());
        setLayersChange(layers.length);
    }, []);

    // Pop layer
    const popLayer = useCallback(() => {
        if (layers.length > 1)
        {
            layers.pop();
            setLayersChange(layers.length);
        }
    }, []);

    // Start capture on mount
    useEffect(() => {
        inputDevices.map(inputDevice => inputDevice.Start(dispatchCallback));
    }, [inputDevices]);

    useEffect(() => {
        // Pause input dispatcher when current tab / window is not active | prevent unintentional gamepad inputs while not on the app
        window.addEventListener('blur', pauseInputDispatch);
        window.addEventListener('focus', resumeInputDispatch);

        return () => {
            window.removeEventListener('blur', pauseInputDispatch);
            window.removeEventListener('focus', resumeInputDispatch);
        };
    }, [resumeInputDispatch, pauseInputDispatch]);

    return (
        <Context.Provider value={{
            registerCallback, unRegisterCallback,
            pauseInputDispatch, resumeInputDispatch,
            pushLayer, popLayer,
            layersCount, getNumberOfGamepads
        }}>
            {children}
        </Context.Provider>
    );
}
