import {IconButton, IconTrailer, NeutralButton} from 'components/ui';
import {useTranslation} from 'react-i18next';
import ReactDOM from 'react-dom';
import {If} from 'helpers';
import {Trailer} from './Trailer';
import {useState} from 'react';
import {isMobileOrTablet} from 'app/device';

const initialState = {visible: false, muted: false};

export function TrailerButton({game, ...props})
{
    const {t}                               = useTranslation();
    const [trailerStatus, setTrailerStatus] = useState(initialState);

    const close = () => {
        setTrailerStatus(initialState);
    };

    const start = (event) => {
        // on mobile devices, auto playback of videos is only allowed if muted, unless
        // it results from a direct user action (trusted event created by the browser)
        // https://webkit.org/blog/6784/new-video-policies-for-ios
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        setTrailerStatus({visible: true, muted: isMobileOrTablet && !event.isTrusted});
    };

    const {visible, muted} = trailerStatus;


    return (
        <>
            <If condition={visible}>
                {ReactDOM.createPortal(
                    <Trailer game={game}
                             muted={muted}
                             onClose={close}
                    />,
                    document.body)}
            </If>
            <IconButton button={NeutralButton} icon={IconTrailer} onClick={start} {...props}>
                {t('buttons.trailer')}
            </IconButton>
        </>
    );
}
