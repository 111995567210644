import LogoNavImage from 'assets/images/logo-nav.png';
import styled, {css} from 'styled-components';
import {tv, mobile, mobileSmall} from 'app/device';

export const LogoNav = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${LogoNavImage})`,
        ...props.style,
    }
}))`
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  width: 136px;
  height: 40px;

  ${mobile(css`
    width: 81px;
    height: 24px;
  `)}

  ${mobileSmall(css`
    width: 79px;
    height: 23px;
  `)}

  ${tv(css`
    width: 157px;
    height: 46px;
  `)}
`;
