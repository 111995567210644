// Re-maps a number from one range to another.
export function remap(value, low1, high1, low2, high2)
{
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
};

export function clamp(num, min, max)
{
    return Math.round(Math.min(Math.max(num, min), max));
}
