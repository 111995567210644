import {Search} from 'services';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isAndroid, isMobile, isTV} from 'app/device';
import {useDispatch, useSelector} from 'react-redux';
import {globalNavigationSelector, toggleGlobalNavigation} from 'slices';

export function useSearch()
{
    const [currentCue, setCurrentCue]                        = useState('');
    const [isSearchFocused, _setIsSearchFocused]             = useState(false);
    const [isMostSearchedVisible, _setIsMostSearchedVisible] = useState(true);
    const isSearchFocusedRef                                 = useRef(isSearchFocused);
    const isMostSearchedVisibleRef                           = useRef(isMostSearchedVisible);
    const searchInputRef                                     = useRef();
    const columns                                            = useRef([]);
    const games                                              = useRef([]);
    const cues                                               = Search.GetMostResearchedCues();
    const mostSearchedGames                                  = useRef(Search.GetMostResearchedGames());
    const {t}                                                = useTranslation();
    const dispatch                                           = useDispatch();
    const windowDimensions                                   = useRef({});
    const globalNav                                          = useSelector(globalNavigationSelector);

    useEffect(() => {
        games.current            = mostSearchedGames.current;
        windowDimensions.current = {w: window.innerWidth, h: window.innerHeight};
    }, []);

    const setIsSearchFocused = (data) => {
        isSearchFocusedRef.current = data;
        _setIsSearchFocused(data);
    };

    const setIsMostSearchedVisible = (data) => {
        isMostSearchedVisibleRef.current = data;
        _setIsMostSearchedVisible(data);
    };

    const onFocusSearchBox = () => {
        setIsSearchFocused(true);
        setIsMostSearchedVisible(false);
        if (isAndroid && globalNav.visible)
            dispatch(toggleGlobalNavigation());
    };

    const onBlurSearchBox = () => {
        setIsSearchFocused(false);
        setIsMostSearchedVisible(true);
        searchInputRef.current.blur();

        //check if nav visible because shouldUnFocus can be triggered multiple times
        if (isAndroid && !globalNav.visible)
            dispatch(toggleGlobalNavigation());
    };

    const shouldUnFocus = (e) => {
        /*
            backPressBtn pressed, show menu and most searched games
            note : backPressBtn is hiding virtual keyboard and provokes resize /!\
         */
        if (isSearchFocusedRef.current && !isMostSearchedVisibleRef.current && window.innerHeight >= windowDimensions.current.h)
            onBlurSearchBox();
    };

    useLayoutEffect(() => {
        if (isAndroid && isMobile)
        {
            window.addEventListener('resize', shouldUnFocus);
            return () => window.removeEventListener('resize', shouldUnFocus);
        }
    }, []);

    const onSearch = (cue) => {

        // Return and clear input if cue equal to nothing
        if (cue.trim().length <= 0)
        {
            searchInputRef.current.value = '';
            return;
        }

        // Do search
        const results = Search.Find(cue, t);

        // Init columns
        let maxCols     = isTV ? 6 : isMobile ? 4 : 5;
        columns.current = [...Array(maxCols)].map(() => []);

        // Fill columns
        results.forEach((game, index) => columns.current[index % maxCols].push(game));

        // Set games
        games.current = results;

        // Set current cue
        setCurrentCue(cue);
    };

    return {
        games            : games.current,
        columns          : columns.current,
        cues,
        onSearch,
        currentCue,
        mostSearchedGames: mostSearchedGames.current,
        isSearchFocused,
        onFocusSearchBox,
        onBlurSearchBox,
        isMostSearchedVisible,
        searchInputRef
    };
}
