import {useDispatch, useSelector} from 'react-redux';
import {globalMessageBoxSelector, hideGlobalMessageBox} from 'slices';
import history from 'app/history';
import {useEffect, useRef} from 'react';

// TODO: refactor this cleanly
let closeCallback                       = undefined;
export const setMessageBoxCloseCallback = (cb) => {
    closeCallback = cb;
};

export function useGlobalMessageBox()
{
    const dispatch                         = useDispatch();
    const {message, type, routeTo, timing} = useSelector(globalMessageBoxSelector);
    const timeoutFunc                      = useRef(null);

    useEffect(() => {
        if (!timing)
            return;

        timeoutFunc.current = setTimeout(() => {
            onClick();
        }, timing);
    });

    const onClick = () => {
        if (timeoutFunc.current)
            clearTimeout(timeoutFunc.current);

        // call custom close callback, then clear if present
        closeCallback?.();
        closeCallback = undefined;

        dispatch(hideGlobalMessageBox());
        if (routeTo)
            history.push(routeTo);
    };

    return {message, type, onClick};
}
