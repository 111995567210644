import * as S from './DownloadAndroid.style';
import {useTranslation} from 'react-i18next';

// see public/var.js
// eslint-disable-next-line no-undef
const appName = APP_TITLE || process.env.REACT_APP_TITLE;

export function DownloadAndroid({onClick})
{

    const {t} = useTranslation();

    return (
        <S.DownloadAndroid onClick={onClick}>
            <div>{t('pwa.download_android', {appName})}</div>
        </S.DownloadAndroid>
    );
}
