import {ConfigFront} from 'services';

export function avatarURL(file)
{
    return `${ConfigFront.GetAssetsUrl()}/avatars/${file}`;
}

export function assetURL(file)
{
    return `${ConfigFront.GetAssetsUrl()}/${file}`;
}

export function trailerURL(file)
{
    return `${ConfigFront.GetAssetsUrl()}/${file}`;
}
