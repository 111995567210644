import {useDispatch, useSelector} from 'react-redux';
import {doLogin, loginSelector, showGlobalMessageBox} from 'slices';
import {useForm} from 'react-hook-form';
import {useCallback, useEffect, useRef} from 'react';
import {ConfigFront} from 'services';
import {useShowError} from 'hooks';
import {useTranslation} from 'react-i18next';
import history from 'app/history';
import Routes from 'app/routes';
import {isSafari, isTV} from 'app/device';
import {setMessageBoxCloseCallback} from 'hooks';

export function useLogin()
{
    const {register, handleSubmit}                          = useForm();
    const dispatch                                          = useDispatch();
    const {isChecking, loggedIn, loginError, hasLoginError} = useSelector(loginSelector);
    const supportMethods                                    = ConfigFront.GetSupportMethods();
    const registerUrl                                       = ConfigFront.GetRegisterUrl();
    const LoginBoxRef                                       = useRef();
    const {dispatchErrorMessageBox}                         = useShowError();
    const {t}                                               = useTranslation();
    const wrapperToFocus                                    = useRef();

    const isWarningVisible = useCallback(() => {
        if (isTV || !isSafari) return false; // warning shown only on Safari (caution, Tizen browser reports as Safari)

        //if warning doesnt exist in local storage, create it
        if (!localStorage.getItem('gamepad_warning_safari'))
        {
            localStorage.setItem('gamepad_warning_safari', new Date().toString());
            return true;
        }

        const storedDate = Date.parse(localStorage.getItem('gamepad_warning_safari'));
        const dateNow    = new Date();
        const diff       = Math.round(((dateNow - storedDate) / 1000) / 60);

        // if more than 3 hours
        if (diff > 4)
        {
            //reset date
            localStorage.setItem('gamepad_warning_safari', new Date().toString());
            return true;
        }
        else
        {
            return false;
        }
    }, []);

    useEffect(() => {
        if (isWarningVisible())
        {
            //timeout to preserve focus on GlobalMessageBox
            setTimeout(() => {
                if (loggedIn)
                {
                    dispatch(showGlobalMessageBox({
                        type   : 'info',
                        message: t('warnings.gamepad.activate'),
                        timing : 5000,
                        routeTo: Routes.MEGA_LOADER,
                    }));
                }
                else
                {
                    setMessageBoxCloseCallback(() => wrapperToFocus.current.focus());
                    dispatch(showGlobalMessageBox({
                        type   : 'info',
                        message: t('warnings.gamepad.activate'),
                        timing : 5000
                    }));
                }
            }, 500);
        }
        else if (loggedIn)
        {
            // push mega loader route
            history.push(Routes.MEGA_LOADER);
        }
    }, [loggedIn, isWarningVisible]);

    useEffect(() => {
        // Show login error
        if (hasLoginError)
        {
            dispatchErrorMessageBox(loginError);
        }
    }, [hasLoginError]);

    const onSubmit = handleSubmit(data => {
        dispatch(doLogin({
            username  : data.username,
            password  : data.password,
            rememberMe: data.rememberMe,
        }));
    });

    return {
        register,
        onSubmit,
        isChecking,
        loggedIn,
        LoginBoxRef,
        supportMethods,
        registerUrl,
        wrapperToFocus
    };
}
