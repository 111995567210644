import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

// cannot import directly from helpers at this point for some reason
import {mergeObjects} from 'helpers/helpers';

// consolidate i18n resources from main set + customer-specific overrides in assets
// this constructs a map between a language key and its consolidated resource
export const supportedLanguages = ['en', 'fr', 'de', 'it', 'zt'];
export const resources          = {};
// tizen < 6 does not have Object.fromEntries
supportedLanguages.map(l => {
    // see https://webpack.js.org/guides/dependency-management/#require-with-expression
    const main     = require('./locales/main_' + l + '.json');
    const override = require('assets/i18n/override_' + l + '.json');
    return [l, {main: mergeObjects(main, override)}];
}).forEach(([key, value]) => {
    resources[key] = value;
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng  : 'en',
        debug        : process.env.NODE_ENV === 'development',
        defaultNS    : 'main',
        supportedLngs: supportedLanguages,
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });


export default i18n;
