import {useEffect} from 'react';
import {useInputDispatcherContext} from 'context';

// Supported buttons:
// - back, select
// - up, down, left, right
export function useButtonPress(button, callback)
{
    const {registerCallback, unRegisterCallback} = useInputDispatcherContext();

    useEffect(() => {
        const [layer, id] = registerCallback(button, callback);
        return () => {
            unRegisterCallback(button, layer, id);
        };
    }, [callback, button]);
}

export const useBackPress               = (callback) => useButtonPress('back', callback);
export const useSelectPress             = (callback) => useButtonPress('select', callback);
export const useUpPress                 = (callback) => useButtonPress('up', callback);
export const useDownPress               = (callback) => useButtonPress('down', callback);
export const useLeftPress               = (callback) => useButtonPress('left', callback);
export const useRightPress              = (callback) => useButtonPress('right', callback);
export const useTriggerBottomLeftPress  = (callback) => useButtonPress('triggerBottomLeft', callback);
export const useTriggerBottomRightPress = (callback) => useButtonPress('triggerBottomRight', callback);
export const useActionTopPress          = (callback) => useButtonPress('actionTop', callback);
