import {useCallback} from 'react';
import {favoritesSelector, profileSelector, setFavorite} from 'slices';
import {useDispatch, useSelector} from 'react-redux';

export function useToggleFavorite()
{
    const dispatch         = useDispatch();
    const {favorites}      = useSelector(favoritesSelector);
    const {currentProfile} = useSelector(profileSelector);

    const toggleGame = useCallback((gameAlias) => {
        dispatch(setFavorite({
            profileUID: currentProfile.uid,
            gameAlias,
            favorite  : !favorites.includes(gameAlias)
        }));
    }, [currentProfile, favorites]);

    return {toggleGame};
}
